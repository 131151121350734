@import './lincoln/fonts';
@import './lincoln/colors';
@import './lincoln/icons';

.brand-lincoln {
    font-family: $proxima-nova-regular;
}

.global-owner-syndicated-lincoln-header {
    .lincolnMainNavigation {
        nav.navbar.navbar-default.navbar-static-top {
            max-width: unset;
            #fgx-main-nav-dropdown_language-item > li > a {
                font-size: 14px;
            }
        }
        .utility-item {
            font-size: 13px;
            letter-spacing: 1px;
            line-height: 1.22;
            .fgx-nav-icon {
                font-size: 16px;
            }
        }
    }
    .search-overlay {
        .modal-content {
            .modal-header {
                a.close {
                    font-size: 50px;
                }
            }
            // don't uncomment it as it breaks Code Splitting for some reason
            // .modal-body {
            //     .form-wrapper {
            //         .lbl .text {
            //             font-size: 14px;
            //         }
            //         .search-overlay-input {
            //             font-size: 42px;
            //         }
            //         .search-overlay-submit > span {
            //             font-size: 50px;
            //         }
            //     }
            // }
            .modal-footer {
                .popular,
                .how {
                    .lbl {
                        font-size: 20px;
                    }
                    ul > li > a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.global-owner-syndicated-lincoln-footer {
    .globalFooter {
        div.alert-banner.fgx-brand-print-hide > p > a > span {
            font-size: 14px;
        }
        .nav.navbar-nav .nav.navbar-nav .text {
            font-size: 14px;
        }
        .social-links .fgx-brand-lt-h6 {
            font-size: 12px;
        }
        .secondary .navbar-inverse .navbar-nav > li > a > .text {
            color: $white;
        }
        .secondary .navbar-inverse .navbar-nav > li.comment-card {
            display: block;
        }
    }
}
