@import './colors';

$fif-tile-size: 16.875rem;

$max-content-width: 90rem;
$max-tab-bar-width: 90rem;

$mobile-width: 780px;
$small-desktop-width: 970px;
$ccpa-width: 1215px;
$ipad-landscape-width: 1023px;
$ipad-portrait-width: 768px;
$max-tablet: 992px;

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0.75rem;
    font-weight: 100;
}

hr {
    box-sizing: border-box;
    height: 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #979797;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin: 0 auto;
}

// global header classes
.hide-me {
    display: none;
}

// pixels to rem
@function pToR($pixels) {
    @return calc($pixels/16) * 1rem;
}

@mixin input-font() {
    font-family: inherit;
    font-size: 0.927rem;
    letter-spacing: 0.058rem;
    line-height: 1.5rem;
    padding: 0 16px 0;
}
@mixin text($size, $letterSpacing, $lineHeight, $color, $fontFamily) {
    font-size: pToR($size);
    letter-spacing: $letterSpacing;
    line-height: $lineHeight;
    color: $color;
    font-family: $fontFamily;
}

.main-overlay {
    display: none;
    position: fixed;
    top: 5.25rem;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: #000 none;

    &.open {
        display: block;
    }
}

.text-center {
    text-align: center;
}

.section-heading-link {
    font-size: pToR(34);
    line-height: pToR(44);
    text-decoration: none;
    color: $text-gray;
}

.mobile-only {
    @include desktop {
        display: none;
    }
}
