.brand-lincoln {
    .smash-find-a-solution-wrapper {
        .find-a-solution {
            background: white;
            @media (min-width: 9px) and (max-width: 850px) {
                padding: 65px 25px 0 25px;
            }
            .search-container {
                padding: 0;
                margin-bottom: 40px;
                form {
                    width: 100%;
                    .autocomplete__input {
                        font-size: pToR(16);
                        font-family: $proxima-nova-regular;
                        color: $fds-color-dk-gray;
                        letter-spacing: 1px;
                    }
                }
            }
            .secondary-button-text {
                display: inline-flex;
                justify-content: center;

                .search-link-icon {
                    margin-right: 12px;

                    img {
                        height: auto;
                        width: auto;
                        vertical-align: middle;
                    }
                }

                .search-link-text {
                    display: inline-flex;
                    align-items: center;
                }
            }
            @media (min-width: 991px) {
                padding: 65px 8px 50px 8px;
                .search-container {
                    padding: 0;
                    margin-bottom: 40px;
                    form {
                        width: pToR(675);
                    }
                }
                .search-links {
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 0 90px 0 130px;
                }
                .search-links > * {
                    flex: 1 1 calc(100% / 4);
                    padding: 0 12px;
                    max-width: calc(100% / 4);
                }
            }
        }
    }
}
