.brand-lincoln {
    .find-a-solution {
        max-width: $max-content-width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 65px 16px 45px 16px;

        .divider {
            box-shadow: none;
            margin-top: 10px;
        }

        .search-container {
            padding: 0;
            margin-bottom: 40px;

            form {
                width: 100%;

                .autocomplete__input {
                    font-size: pToR(16);
                    font-family: $proxima-nova-regular;
                    color: $fds-color-dk-gray;
                    letter-spacing: 1px;
                }
            }
        }

        .search-links {
            display: flex;
            flex-direction: column;

            .find-a-solution-smart-tiles-container {
                margin: pToR(30) 0;

                .find-a-solution-smart-tiles {
                    display: flex;
                    align-items: center;
                    margin-bottom: pToR(6);

                    &:hover {
                        text-decoration: underline red 2px;
                        text-underline-offset: pToR(8);
                    }

                    &:focus {
                        outline: rgb(50, 64, 71) solid 1px;
                    }

                    .recalls-tile-icon {
                        width: pToR(80);
                    }

                    .smart-tiles-content {
                        padding: 0 pToR(12);
                        font-family: 'proximanova-sbold', Arial, Helvetica,
                            sans-serif;
                        color: #324047;
                        letter-spacing: 1px;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        margin-bottom: pToR(10);

                        @include mobile {
                            text-align: left;
                        }

                        &:hover {
                            cursor: pointer;

                            .secondary-button-text {
                                border-bottom: 2px solid transparent;
                            }

                            .search-link-text {
                                border-bottom: 2px solid $brand-border;
                            }
                        }
                    }
                }
            }
        }

        .search-link-container {
            margin: 24px 0;
        }

        .search-link {
            padding: 0;
            justify-content: left;
            height: 50px;
            margin-bottom: 6px;

            .search-link-text {
                padding-bottom: 5px;
            }

            &:hover {
                cursor: pointer;

                .secondary-button-text {
                    border-bottom: 2px solid transparent;
                }

                .search-link-text {
                    border-bottom: 2px solid $brand-border;
                }
            }
        }

        .secondary-button {
            display: block;
        }

        .search-link-icon {
            width: 80px;
            display: inline-block;
            text-align: left;
            margin-right: 0;

            img {
                height: auto;
                width: auto;
                vertical-align: middle;
            }
        }

        @media (min-width: 991px) {
            padding: 65px 8px 70px 8px;
            .search-container {
                padding: 0;
                margin-bottom: 40px;

                form {
                    width: pToR(675);
                }
            }
            .search-links {
                flex-direction: row;
                flex-wrap: wrap;
            }
            .search-links > * {
                flex: 1 1 calc(100% / 3);
                padding: 0 12px;
            }
        }
    }
}
