.brand-ford {
    font-family: $f1-font-regular;
    letter-spacing: 0;
    .footer {
        @include print {
            display: none;
        }
    }
}

.global-owner-syndicated-ford-header {
    .fordMainNavigation {
        nav.navbar.navbar-default.navbar-static-top {
            max-width: unset;
        }
        .main-nav-items.navbar-nav {
            display: block;
        }

        @include desktop {
            .container-fluid.eyebrow:first-child {
                display: flex !important;
            }
        }
    }

    #global-ux {
        overflow-x: visible;
        padding-bottom: 45px;
        @media (min-width: 767px) and (max-width: 1266px) {
            padding-bottom: 0px;
        }
    }

    #global-ux .main-nav .categories {
        top: 70px;
        right: 0;
        left: 0;
        bottom: auto;
        overflow: visible;
        z-index: 99999;
    }
}
.global-owner-syndicated-ford-fr-ca-header {
    .fordMainNavigation {
        .main-nav-items.navbar-nav {
            @include desktop {
                display: flex;
            }
        }
    }
}
.global-owner-syndicated-ford-footer {
    .globalFooter {
        div.alert-banner.fgx-brand-print-hide > p > a > span {
            font-size: 18px;
        }
        .nav.navbar-nav .nav.navbar-nav .text {
            font-size: 14px;
        }
    }
}
