.brand-lincoln {
    .smash-vehicle-selector {
        max-width: $max-content-width;
        padding: 50px 10px 64px 10px;
        margin: 0 auto;
        background: white;
        @media (min-width: 450px) and (max-width: 850px) {
            margin-right: 0;
            padding: 50px 30px 64px 30px;
        }
        @include desktop {
            padding: 50px 24px 16px 24px;
        }
        .vehicle-selector-header {
            text-align: center;
            font-family: $proxima-nova-regular;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            color: $text-gray;
            padding-bottom: 22px;
            letter-spacing: 1px;

            @include mobile {
                font-size: 24px;
                margin-bottom: 16px;
            }
        }
        .vehicle-selector-subcopy {
            color: $proxima-nova-regular;
            font-family: $proxima-nova-regular;
            font-size: 16px;
            line-height: 26px;
            display: inline-block;
            letter-spacing: 1px;
            text-align: center;
            width: 100%;
            @include desktop {
                width: 100%;
                line-height: 17px;
                padding-bottom: 0;
                margin-left: 0;
            }
            .second-signin-button {
                display: inline;
                button {
                    margin: 0;
                    padding: 0;
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    text-decoration: underline;
                }
            }
        }
    }
    .vehicle-selector-description {
        color: $fds-color-dk-gray;
        font-family: $f1-font-regular;
        margin-bottom: 24px;
        letter-spacing: 1px;
        text-align: center;
    }
    .vehicle-selector-container {
        display: flex;
        flex-direction: row;
        width: 90%;
        @media (min-width: 450px) and (max-width: 850px) {
            width: 100%;
        }
        justify-content: space-evenly;
        margin-left: auto;
        @include mobile {
            margin: auto;
            flex-direction: column;
            justify-content: center;
        }
        @media (min-width: 450px) and (max-width: 850px) {
            margin: auto;
            flex-direction: column;
            justify-content: center;
        }
        .vin-container {
            max-width: 420px;
            width: 100%;
            padding-right: 25px;
            padding-top: 20px;
            @include mobile {
                padding: 0 0 25px 0;
            }
            @media (min-width: 450px) and (max-width: 850px) {
                max-width: 760px;
                padding: 0 0 25px 0;
            }

            .ymm-vin-container {
                max-width: 395px;
                width: 100%;
                @media (min-width: 9px) and (max-width: 850px) {
                    max-width: 760px;
                    width: 100%;
                }
                .ymm-vin-body {
                    max-width: 395px;
                    width: 100%;
                    display: block;
                    justify-content: space-evenly;
                    @include mobile {
                        max-width: 350px;
                    }
                    @media (min-width: 450px) and (max-width: 850px) {
                        max-width: unset;
                        width: 760px;
                        padding: 0 25px 0 25px;
                    }
                }

                .input-field-container {
                    margin: unset;
                    padding-top: 0;
                    padding-bottom: 0;
                    @include mobile {
                        height: 52px;
                    }
                    .input-label {
                        display: none;
                    }
                    .input-field {
                        position: unset;
                        margin-top: 17px;
                        @include mobile {
                            margin: unset;
                            position: relative;
                        }
                    }
                }
                .vehicle-selector-button {
                    align-content: start;
                    margin: 62px 0 0 0;
                    height: 50px;
                    @include mobile {
                        margin: 40px auto auto auto;
                    }
                    @media (min-width: 450px) and (max-width: 850px) {
                        margin: 40px auto auto auto;
                    }
                }
            }
        }

        .selector-divider {
            @include desktop {
                height: 72px;
                border-left: 1px solid black;
                padding-left: 25px;
                margin-top: 20px;
            }
            @include mobile {
                height: 0px;
                width: 100%;
                border-left: 0px;
                border-top: 1px solid black;
                padding-bottom: 25px;
            }
            @media (min-width: 450px) and (max-width: 850px) {
                height: 0px;
                width: 100%;
                border-left: 0px;
                border-top: 1px solid black;
                padding-bottom: 25px;
            }
        }
        .ymm-container {
            width: 100%;
            justify-content: center;
            @include mobile {
                padding-right: 0px;
                width: auto;
            }
            @media (min-width: 450px) and (max-width: 850px) {
                max-width: 760px;
            }

            .ymm-vin-container {
                width: 100%;
                max-width: 709px;
                @media (min-width: 450px) and (max-width: 850px) {
                    max-width: 760px;
                }
                .ymm-vin-body {
                    width: 100%;
                    @media (min-width: 450px) and (max-width: 850px) {
                        max-width: 760px;
                        width: 100%;
                    }
                    .ymm-container-dropdown {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        width: 100%;
                        @include desktop {
                            flex-direction: row;
                        }
                        .dropdown-container-wrapper {
                            padding-top: 31px;
                            flex-direction: column;

                            @include mobile {
                                padding-bottom: 1.25rem;
                            }

                            .year-dropdown {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                @include desktop {
                                    margin-right: 20px;
                                    width: 288px;
                                }
                            }

                            .model-dropdown {
                                min-width: auto;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                @include desktop {
                                    margin-top: 0;
                                    height: 60px;
                                    width: 390px;
                                }
                            }
                            .dropdown-button {
                                @include desktop {
                                    height: 60px;
                                }
                            }

                            .error-message {
                                top: -1.8rem;
                                @include tablet-portrait {
                                    top: -3rem;
                                }
                            }
                        }
                    }
                }
                .vehicle-selector-button {
                    margin-top: 62px;
                    margin-left: 0;
                    @include mobile {
                        margin: 40px auto auto auto;
                    }
                    @media (min-width: 450px) and (max-width: 850px) {
                        margin: 40px auto auto auto;
                    }
                }
            }
        }
    }
}
