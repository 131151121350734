@font-face {
    font-family: FdsFordIcons;
    src: url('/fonts/ford/ford-icons/ford-icons.woff2?tnd5m') format('woff2'),
        url('/fonts/ford/ford-icons/ford-icons.woff?tnd5m') format('woff');
}
@font-face {
    font-family: FordF1Bold;
    src: url('/fonts/ford/f1-fonts/FordF-1-Bold.woff2') format('woff2');
}
@font-face {
    font-family: FordF1Light;
    src: url('/fonts/ford/f1-fonts/FordF-1-Light.woff2') format('woff2');
}
@font-face {
    font-family: FordF1Regular;
    src: url('/fonts/ford/f1-fonts/FordF-1-Regular.woff2') format('woff2');
}
@font-face {
    font-family: FordF1Semibold;
    src: url('/fonts/ford/f1-fonts/FordF-1-Semibold.woff2') format('woff2');
}
@font-face {
    font-family: FordF1Medium;
    src: url('/fonts/ford/f1-fonts/FordF-1-Medium.woff2') format('woff2');
}
$fds-chevron-right: '\e902';
$fds-chevron-dot: '\e901';
$fds-chevron-right-unfilled: '\e90a';

$f1-font-bold: FordF1Bold, sans-serif;
$f1-font-light: FordF1Light, sans-serif;
$f1-font-medium: FordF1Medium, sans-serif;
$f1-font-regular: FordF1Regular, sans-serif;
$f1-font-semibold: FordF1Semibold, sans-serif;
$fds-ford-icon: 'fds-ford-icons';
