.brand-ford {
    .shared-view.smash-home {
        margin-top: 0;
    }
    .smash-home {
        .placeholder {
            border: 1px solid red;
            width: 100%;
            text-align: center;
            background-color: $fds-color-white;
        }
        .cta-text {
            text-align: center;
            padding: 4rem 1.344rem 1.344rem;

            @include tablet {
                padding: 6.375rem 0 4rem;
            }

            .title {
                font-family: $f1-font-light;
                text-transform: uppercase;
                font-size: pToR(60);
                line-height: pToR(70);
                margin-bottom: 8px;

                @include desktop {
                    max-width: inherit;
                }
            }

            .paragraph {
                font-size: 1rem;
                line-height: 1.625rem;
                font-family: $f1-font-regular;
                @include desktop {
                    max-width: 650px;
                    margin: 0 auto;
                }
            }

            .asterisk {
                @include tablet {
                    display: none;
                }
            }
            .home-sign-in-container {
                margin-top: 20px;
            }
        }
        .not-user-button {
            text-decoration: underline;
            margin-top: 0.5rem;
            color: white;
            :hover {
                color: white;
            }
        }
    }
}
