.brand-lincoln {
    .smash-home {
        .placeholder {
            border: 1px solid red;
            width: 100%;
            text-align: center;
            background-color: $fds-color-white;
        }
        .cta-text {
            text-align: center;
            padding: 4rem 1.344rem 1.344rem;

            @include tablet {
                padding: 6.375rem 0 4rem;
            }

            .title {
                font-family: $proxima-nova-light;
                text-transform: uppercase;
                font-size: pToR(60);
                letter-spacing: 3px;
                font-weight: 200;
                line-height: pToR(70);
                margin-bottom: 8px;

                @include desktop {
                    max-width: inherit;
                }
            }

            .paragraph {
                font-size: 1rem;
                line-height: 1.625rem;
                letter-spacing: 1px;
                @include desktop {
                    max-width: 650px;
                    margin: 0 auto;
                }
            }

            .asterisk {
                @include tablet {
                    display: none;
                }
            }
            .home-sign-in-container {
                margin-top: 20px;
            }
        }
        .feature-trio {
            max-width: 1324px;

            @include tablet {
                margin: 100px 0;
                padding: 0 100px;
            }

            @include desktop {
                max-width: 1324px;
                width: 100%;
                margin: 100px auto;
                padding: 0px 0px;
            }
            @include md-desktop {
                width: 95%;
            }

            .feature-trio-heading-two {
                font-family: $proxima-nova-semi-bold;
                font-size: pToR(24);
                color: $brand-secondary;

                .secondary-button > a {
                    padding-right: 15px;
                    padding-top: 36px;
                    padding-bottom: 8px;
                }
            }

            .feature-trio-cards__title-two {
                font-family: $proxima-nova-regular;
                font-size: pToR(24);
                letter-spacing: 2px;
            }

            .feature-trio-cards {
                column-gap: 24px;
                @include desktop {
                    padding: 0 0 0 0;
                }
                .card-wrap {
                    width: 100%;

                    @include tablet {
                        width: 100%;
                    }
                    @include desktop {
                        width: 100%;
                        padding: 0 0 0 0;
                    }
                    .card {
                        box-shadow: none;
                        margin-bottom: 24px;
                        width: 100%;
                        .card-body {
                            width: 100%;
                            padding: 0 0 8px 0;
                        }
                        .card-header {
                            height: 260px;
                        }
                        .card-title {
                            text-align: left;
                            max-width: 500px;
                            width: 100%;
                            padding: 24px 0 16px 0;
                            letter-spacing: 0px;
                            margin-bottom: 0px;
                            font-size: pToR(18);
                            line-height: 26px;

                            @include tablet {
                                font-size: pToR(18);
                            }
                            @include desktop {
                                font-size: pToR(18);
                            }
                        }
                        .card-description {
                            padding: 0px 40px 8px 0;
                            font-size: pToR(18);
                            margin-bottom: 64px;
                            @include tablet {
                                padding-top: 0;
                                font-size: pToR(16);
                            }
                            @include desktop {
                                font-size: pToR(16);
                                margin-bottom: 16px;
                                padding: 0px 64px 064px 0;
                            }
                            @media (min-width: 1024px) and (max-width: 1150px) {
                                padding-right: 16px;
                            }
                        }

                        .primary-button {
                            position: relative;
                            font-family: $proxima-nova-semi-bold;
                            color: $primary-graphite;
                            background-color: transparent;
                            padding: 10px 42px 0px 42px;
                            width: 24px;
                        }
                    }
                }
            }
        }
        .not-user-button {
            text-decoration: underline;
            margin-top: 0.5rem;
            color: white;
        }
    }
}
