@import '../../../../../styles/main';

.brand-ford {
    .fm-dropdown-container {
        max-width: unset;
        width: 100%;
        margin-top: 35px;
        padding-right: 10px;
        padding-bottom: 60px;
        .fm-dropdown {
            max-width: unset;
            width: 100%;
        }
    }
}
