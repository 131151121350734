.brand-lincoln {
    .masthead {
        margin: 0;
        padding: 0;
        text-align: center;
        color: $white;
        background-size: cover;
        background-position: center center;
        min-height: 450px;
        .masthead__wrapper {
            margin: 0 auto;
            max-width: 550px;
            padding: 112px 0px 150px 0px;

            font-family: $proxima-nova-light;
            letter-spacing: 1px;
            @include tablet {
                max-width: 700px;
                padding: 60px 0 80px 0;
            }
            @include desktop {
                max-width: 900px;
            }
            .masthead__category-title {
                margin: 0px;
                font-size: pToR(40);
                line-height: 4.375rem;
                letter-spacing: 2px;
            }
            .masthead__category-headline {
                margin-top: 25px;
                font-family: $lincoln-millerb-black;
                letter-spacing: 0px;
                font-size: pToR(40);
                line-height: 48px;
                @include tablet {
                    margin-top: 125px;
                    font-size: pToR(60);
                    line-height: 60px;
                }
                @include desktop {
                    width: 100%;
                    margin-top: 100px;
                    line-height: 70px;
                    font-size: pToR(60);
                    height: 70px;
                }
            }
            .masthead__description {
                letter-spacing: 0.15px;
                padding: 6px 45px 0px 45px;

                font-family: $proxima-nova-light;
                font-size: 1rem;
                line-height: pToR(24);
                @include tablet {
                    padding: 6px 120px 0px 120px;
                }
                @include desktop {
                    padding: 6px 200px 0px 200px;
                    font-size: pToR(18);
                }
            }
            .masthead__cta {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2rem;
                .primary-button {
                    font-size: 1.125rem;
                    line-height: 0.8125rem;
                }
            }
        }
    }
}
