$lincoln-proxima-font-directory: '/fonts/lincoln/proxima';
$lincoln-miller-font-directory: '/fonts/lincoln/miller';

/* proxima nova regular */
@font-face {
    font-family: 'proxima-nova-regular';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.woff2')
            format('woff2'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova light */
@font-face {
    font-family: 'proxima-nova-light';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.woff2')
            format('woff2'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova semibold */
@font-face {
    font-family: 'proximanova-sbold';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.woff2')
            format('woff2'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova bold */
@font-face {
    font-family: 'proxima-nova-bold';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.woff2')
            format('woff2'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller semi-bold */
@font-face {
    font-family: 'lincolnmillerbsemibold';
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.woff2')
            format('woff2'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller light italic */
@font-face {
    font-family: 'lincolnmillerblightitalic';
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.woff2')
            format('woff2'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller light */
@font-face {
    font-family: 'lincolnmillerblight';
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.woff2')
            format('woff2'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller roman */
@font-face {
    font-family: 'lincolnmillerbroman';
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.woff2')
            format('woff2'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller black */
@font-face {
    font-family: 'lincolnmillerbblack';
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-black-webfont.woff2')
            format('woff2'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-black-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

//TYPEFACES
$proxima-nova-light: 'proxima-nova-light', Arial, Helvetica, sans-serif;
$proxima-nova-regular: 'proxima-nova-regular', Arial, Helvetica, sans-serif;
$proxima-nova-bold: 'proxima-nova-bold', Arial, Helvetica, sans-serif;
$proxima-nova-semi-bold: 'proximanova-sbold', Arial, Helvetica, sans-serif;
$lincoln-millerb-black: 'lincolnmillerbblack', Arial, Helvetica, sans-serif;
$lincoln-millerb-semibold: 'lincolnmillerbsemibold', Arial, Helvetica,
    sans-serif;
$lincoln-millerb-lightitalic: 'lincolnmillerblightitalic', Arial, Helvetica,
    sans-serif;
$lincoln-millerb-light: 'lincolnmillerblight', Arial, Helvetica, sans-serif;
$lincoln-millerb-roman: 'lincolnmillerbroman', Arial, Helvetica, sans-serif;
