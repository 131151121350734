.brand-ford {
    .masthead {
        margin: 0;
        padding: 0;
        text-align: center;
        color: $white;
        background-size: cover;
        background-position: center center;
        min-height: 450px;
        @include mobile-landscape {
            min-height: 350px;
        }
        @include tablet {
            min-height: 350px;
        }
        .masthead__wrapper {
            margin: auto 5%;
            padding: 100px 0 100px 0;
            font-family: $f1-font-light;
            @include tablet {
                margin: auto 10%;
            }
            @include desktop {
                padding: 100px 0;
                margin: auto;
                width: 700px;
            }
            .masthead__category-title {
                margin: 0;
                text-transform: uppercase;
                font-size: 3.25rem;
                line-height: 4.375rem;
            }
            .masthead__description {
                padding: 0 32px 0 32px;
                font-family: $f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(26);
            }
            .masthead__category-headline {
                margin-bottom: 16px;
                font-size: pToR(34);
                line-height: pToR(44);
                font-family: $f1-font-light;
                @include desktop {
                    font-size: pToR(48);
                    line-height: pToR(58);
                }
                &.masthead__category-headline-vietnamese {
                    font-family: $f1-font-light;
                }
            }
            .masthead__cta {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2rem;
                .primary-button {
                    font-size: 1.125rem;
                    line-height: 0.8125rem;
                }
            }
        }

        &.cx-home-page {
            @include l-desktop {
                min-height: 500px;
            }
            .masthead__category-headline {
                font-family: $f1-font-light;
            }
        }
    }
}
