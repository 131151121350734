@mixin tablet {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin md-desktop {
    @media (min-width: 1024px) {
        @content;
    }
}
@mixin md-desktopMax {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin l-desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin xl-desktop {
    @media (min-width: 1700px) {
        @content;
    }
}

@mixin xxl-desktop {
    @media (min-width: 3000px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mobile-landscape {
    @media (min-width: 480px) and (max-width: 767px) {
        @content;
    }
}

@mixin xs-mobile {
    @media (max-width: 350px) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin print {
    @media print {
        @page {
            margin: 0 !important;
            size: A4 Landscape !important;
            -webkit-print-color-adjust: exact;
        }
        @content;
    }
}

@mixin fds-x-large {
    @media (min-width: 1920px) {
        @content;
    }
}

@mixin fds-large {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin fds-medium {
    @media (min-width: 1024px) {
        @content;
    }
}
@mixin fds-small {
    @media (min-width: 600px) and (max-width: 1023px) {
        @content;
    }
}
@mixin fds-x-small {
    @media (min-width: 320px) and (max-width: 599px) {
        @content;
    }
}
