.brand-ford {
    .find-a-solution {
        max-width: $max-content-width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 65px 16px 45px 16px;
        .divider {
            box-shadow: none;
            margin-top: 10px;
        }
        .search-container {
            padding: 0;
            margin-bottom: 40px;
            form {
                width: 100%;
                .autocomplete__input {
                    font-size: pToR(16);
                    font-family: $f1-font-regular;
                    color: $fds-color-dk-gray;
                }
            }
        }

        .search-links {
            display: flex;
            flex-direction: column;
        }
        .search-link-container {
            margin: 24px 0;
        }
        .search-link {
            padding: 0;
            justify-content: left;
            height: 50px;
            margin-bottom: 6px;
        }
        .secondary-button {
            display: block;
            height: 50px;
        }
        .search-link-icon {
            width: 85px;
            display: inline-block;
            text-align: left;
            margin-right: 12px;
            img {
                height: auto;
                width: auto;
                vertical-align: middle;
            }
        }
        @media (min-width: 991px) {
            padding: 65px 8px 70px 8px;
            .search-container {
                padding: 0;
                margin-bottom: 40px;
                form {
                    width: pToR(675);
                }
            }
            .search-links {
                flex-direction: row;
                flex-wrap: wrap;
            }
            .search-links > * {
                flex: 1 1 calc(100% / 3);
                padding: 0 12px;
            }
        }
    }
}
