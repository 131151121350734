.brand-ford {
    .smash-find-a-solution-wrapper {
        .find-a-solution {
            background: white;
            max-width: $max-content-width;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            @media (min-width: 9px) and (max-width: 850px) {
                padding: 65px 25px 0 25px;
            }
            .divider {
                box-shadow: none;
                margin-top: 10px;
            }
            .search-container {
                padding: 0;
                margin-bottom: 40px;
                form {
                    width: 100%;
                    .autocomplete__input {
                        font-size: pToR(16);
                        font-family: $f1-font-regular;
                        color: $fds-color-dk-gray;
                    }
                }
            }
            .search-links {
                display: flex;
                flex-direction: column;
            }
            .search-link-container {
                margin: 24px 0;
                width: 100%;
            }
            .search-link {
                padding: 0;
                justify-content: left;
                height: 50px;
                margin-bottom: 6px;
            }
            .secondary-button-text {
                display: inline-flex;
                justify-content: center;

                .search-link-icon {
                    margin-right: 12px;

                    img {
                        height: auto;
                        width: auto;
                        vertical-align: middle;
                    }
                }

                .search-link-text {
                    display: inline-flex;
                    align-items: center;
                }
            }

            .search-links {
                flex-direction: row;
                flex-wrap: wrap;
            }

            @media (min-width: 991px) {
                padding: 65px 8px 25px 8px;
                .search-container {
                    padding: 0;
                    margin-bottom: 40px;

                    form {
                        width: pToR(675);
                    }
                }
                .search-links {
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 0 90px 0 130px;
                }
                .search-links > * {
                    flex: 1 1 calc(100% / 4);
                    padding: 0 12px;
                    max-width: calc(100% / 4);
                }
            }
        }
    }
}
