@import './fonts';
@import './colors';
@import './breakpoints';
@import './shadows';
@import './borders';
@import './common';
@import './main.ford.scss';
@import './main.lincoln.scss';

html {
    font-size: 16px;
}

body {
    margin: 0 auto;
    color: $black;
    font-family: $f1-font-regular;
    max-width: 1440px;
    background-color: $fds-color-surface;
}

.fgx-brand-css {
    .cookie-notice-overlay {
        .overlay-content {
            font-size: 1rem;
        }
    }

    .fgx-btn-content-wrap {
        .title-text {
            font-size: 16px;
        }
    }

    .disclosures-container {
        color: $dropdown-text;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 15px;

        @include desktop {
            padding-left: 102px;
            padding-right: 103px;
        }
    }
}
