/* FDS base colors*/
$fds-color-white: #fff;
$fds-color-black: #000;
$fds-color-transparent: rgba(255, 255, 255, 0);
$fds-color-primary: #00095b;
$fds-color-secondary: #1700f4;
$fds-color-surface: #f4f4f4;
$fds-color-lt-gray: #f2f2f2;
$fds-color-md-gray: #6e6e6e;
$fds-color-dk-gray: #4d4d4d;
$fds-color-disabled-dark: #6e6e6e;
$fds-color-disabled-light: #8c8c8c;
$fds-color-border-gray: #a3a3a3;
$fds-color-border-md-gray: #979797;
$fds-color-red: #d62d14;
$fds-color-orange: #ba4e00;
$fds-color-green: #008200;
$fd-color-strong-black: #1a1a1a;
$fds-color-overlay: rgba(0, 0, 0, 0.4);
/* FDS base colors end*/

//This is from the InVision design (as of 4/1/2020)
$border-grey: #979797;
$disabled-dropdown: #6e6e6e;
$selected-dropdown: #c4c3c3;
$dropdown-text: #4d4d4d;
$eyebrow-background: #ccc;
$darkgrey: #666666;
$error-text: #d62d14;
$fds-delete-overlay: rgba(0, 0, 0, 0.1);
$popover-background: rgba(0, 0, 0, 0);
$error-message-text: #d62d0a;
$warning-message-text: #d00c1b;

//Color added for accessibility (story 182)
$search-text: $dropdown-text;

//Official FDS colors
$highlight-blue: #0672b3;
$light-skyblue: #329ad2;
$light-blue: #1700f4;
$hover-blue: #0276b3;
$link-blue: #0078a0;
$link-blue-and-bold: #008cba;
$border-grey: #a7a7a7;
$border-neutral: #cccccc;
$f1-text-blue: #1f3047;
$fill-on-light: #f5f6f7;
$on-light-primary: #010203;
$fm-skyview: #066fef;
$fm-denim-blue: #055ecb;
$fm-breadcrumb-blue: #0562d2;
$fm-text-grey: #333333;
$fm-text-light-grey: #808080;
$fm-text-blue: #044ea7;
$fm-notification-background: #e4f1ff;
$fm-copy-dark: #2e2f2d;
$fm-moderate-on-dark: #f0f0f0;
$fm-vehicle-card-info: #272727;
//Official Owner Colors
$dark-blue: $fds-color-primary;
$gray: #f2f2f1;
$brand-while-smoke: #efefef;
$lightgrey: #e5e5e5;
$quicklink-textactive: #555;
$eugrey: #1b394e;
$white: #ffffff;
$whitesmoke: #f5f5f5;
$grey: #808080;
$black: #090a09;
$help-text: #949494;
$eyebrow-color: #666;
$eyebrow-grey: #f7f7f7;
$quicklink-text: #2d96cd;
$eyebrow-text: #0e2a4f;
$box-shadow-gray: $help-text;
$dyf-color-darkblue: #02033b;
$dyf-color-darkgrey: #333333;
$slate-grey: #6d7278;
$rebates-tab-grey: #717070;
$rebates-button-orange: #f26146;
$tracker-lt-blue: #1a4782;
$tracker-dk-blue: #0c0d0f;
$disabled-on-dark: #9c9c9c;
$breadcrumb-blue: #00095b;
$grabber-blue: #1700f4;
$text-blue: #102b4e;
$banner-lightgrey-background: #fafafa;

// HiFi Colors
$text-gray: $fds-color-dk-gray;
$medium-gray: #eeeeee;
$white-smoke: #f2f2f2;

$good: #008200;
$moderate: #ba4e00;
$severe: #d62d14;

//Lincoln Colors (per Lauren)
$primary-graphite: #324047;
$secondary-accent-coral: #f26147;
$tertiary-graphite-light: #ecedee;

//EU Colors
$euheader-dark-blue: #102b4e;
$euheader-light-grey: #d7d7d7;
$euheader-cyan-blue: #002f6c;
$euheader-light-blue: #2861a4;
$euheader-shadow-gray: #b3b3b3;
$euheader-dusty-grey: #979797;
$background-lightgray: #f1f0f0;
$text-gray: #616161;
$border-blue: #062b51;
$background-dark-blue: #044ea7;

//invision design 2022 :
$fds-text: #00142e;
$light-gray: #e0e0e0;
//cx
$hover-blue: #366fb3;
//Figma design 2024
$scard-light-grey: #fafafb;
$sidebar-dark-grey: #38485c;
$fds-color-lt-gray-b: #b2b2b2;
$light-sky-blue: #cde2fc;
$fm-danger: #bf152c;
$fm-caution-subtle: #fcf2e6;
$fm-danger-subtle: #f9e8ea;
$fm-gradient-blue: #e3eaf0;
$fm-gradient-white: #fefefe;
$fm-placeholder-grey: #606060;
$text-warning-orange: #b16201;
$medium-grey-text: #585858;
