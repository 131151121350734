.brand-ford {
    .smash-card {
        position: relative;
        background-color: $white;
        margin: 60px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $dropdown-text;
        @include md-desktop {
            flex-direction: row;
            margin: 100px auto;
        }

        .smash-card__image {
            width: 100%;
            height: 450px;
            background-size: cover;
            background-position: center center;
            @include md-desktop {
                width: 50%;
            }
        }
        .smash-card__body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 28px;
            margin-top: 36px;

            @include md-desktop {
                width: 50%;
                align-items: flex-start;
                padding: 0 100px;
                margin-top: 60px;
            }

            .smash-card__body-title {
                text-transform: uppercase;
                font-size: 16px;
                line-height: 24px;
            }
            .smash-card__body-headline {
                text-transform: uppercase;
                @include md-desktop {
                    text-align: left;
                    max-width: 490px;
                }
            }
            .smash-card__body-description {
                font-size: 1rem;
                line-height: 1.625rem;
                font-family: $f1-font-regular;
                text-align: center;
                @include md-desktop {
                    text-align: left;
                }
            }
            .smash-card__body-cta {
                margin: 32px 0 60px;
            }
            .smash-card-disclaimer {
                font-size: 11px;
                color: #4d4d4d;
            }
        }
    }
}
