@import '../../../styles/main';
.brand-ford {
    .vehicle-selector-wrapper {
        .smash-vehicle-selector {
            display: flex;
            flex-direction: column;
            max-width: $max-content-width;
            margin: auto;
            background: white;
            padding: 0 56px 80px;
            @include mobile {
                padding: 0px 16px 48px 16px;
            }

            .pre-imagine-vehicle-selector-header {
                font-family: $f1-font-regular;
                font-size: 32px;
                font-style: normal;
                line-height: 40px;
                color: $f1-text-blue;
                padding-bottom: 16px;

                @include mobile {
                    font-size: 28px;
                }
            }

            .pre-imagine-vehicle-selector-subcopy {
                color: $f1-text-blue;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                display: inline-block;
                width: 100%;
                padding-bottom: 48px;
                @include mobile {
                    width: 100%;
                    line-height: 17px;
                    padding-bottom: 32px;
                    margin-left: 0;
                    font-size: 16px;
                }

                .second-signin-button {
                    display: inline;

                    .button {
                        margin: 0;
                        padding: 0;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        text-decoration: underline;
                    }
                }
            }
        }

        .pre-imagine-vehicle-selector-description {
            color: $f1-text-blue;
            font-family: $f1-font-regular;
            margin-bottom: 24px;
            text-align: center;
        }

        .pre-imagine-vehicle-selector-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 32px;
            @include mobile {
                flex-direction: column;
                justify-content: center;
            }
            @media (min-width: 450px) and (max-width: 850px) {
                flex-direction: column;
                justify-content: center;
            }

            .pre-imagine-vin-container {
                .pre-imagine-ymm-vin-container {
                    width: 100%;
                    .pre-imagine-vin-input-container {
                        width: 100%;
                        .fm-input-container {
                            padding-bottom: 35px;
                            width: 320px;
                            @include mobile {
                                width: 100%;
                                max-width: unset;
                            }
                            @media (min-width: 450px) and (max-width: 850px) {
                                width: 100%;
                                max-width: unset;
                            }
                            .fm-input-header {
                                height: 20px;
                            }
                            .fm-input {
                                width: 100%;
                                @include mobile {
                                    max-width: unset;
                                }
                                @media (min-width: 450px) and (max-width: 850px) {
                                    max-width: unset;
                                }
                            }
                        }
                    }
                }
            }

            .pre-imagine-selector-divider {
                padding: 0;
                @include desktop {
                    height: 72px;
                    border-left: 1px solid black;
                    margin-top: 5px;
                }
                @include mobile {
                    height: 0;
                    width: 100%;
                    border-left: 0;
                    border-top: 1px solid black;
                }
                @media (min-width: 450px) and (max-width: 850px) {
                    height: 0;
                    width: 100%;
                    border-left: 0;
                    border-top: 1px solid black;
                    padding-bottom: 25px;
                }
            }

            .pre-imagine-ymm-container {
                width: 100%;
                .pre-imagine-ymm-vin-container {
                    padding-right: 35px;
                    width: 100%;
                    display: unset;
                    max-width: unset;
                    align-items: unset;
                    @include mobile {
                        padding: 0;
                    }
                }

                .pre-imagine-ymm-vin-body {
                    width: 100%;
                }

                .pre-imagine-ymm-container-dropdown {
                    display: flex;
                    gap: 32px;
                    padding-bottom: 35px;
                    @include mobile {
                        flex-direction: column;
                        width: 100%;
                    }
                    @media (min-width: 450px) and (max-width: 850px) {
                        flex-direction: column;
                        width: 100%;
                    }
                    .fm-dropdown-container {
                        width: fit-content;
                        margin-top: 0;
                        padding-bottom: 0;
                        @include mobile {
                            margin: 0;
                            padding: 0;
                            width: unset;
                        }
                        @media (min-width: 450px) and (max-width: 850px) {
                            width: unset;
                        }
                        .fm-dropdown-header {
                            display: inline-block;
                            height: 20px;
                        }
                        .fm-dropdown {
                            width: 320px;
                            @include mobile {
                                max-width: unset;
                                width: 100%;
                            }
                            @media (min-width: 450px) and (max-width: 850px) {
                                max-width: unset;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .find-vin-cta {
            padding-top: 18px;
            a {
                margin-top: 18px;
                font-size: 12px;
                color: $f1-text-blue;
            }
        }
    }
}
